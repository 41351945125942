.form-error-msg {
    color: red;
    font-weight: bold;
}

.form-container {
    padding-right: 0;
    padding-left: 0;
}
div.spacer {
    display: inline;
    width: 50px;
}

.mark, mark {
    background-color: yellow !important;
}

label {
    font-weight: bold;
}

label.required:after {
    content: "*";
    color: red;
}

label.inline, li.inline {
    font-size: .875rem;

}

div.inline {
    font-size: .875rem;
    font-weight: normal;
}

.read-only {
   border: 0;
   padding-left: 0;
}

.otherFieldGroup {
  margin-left: 5%;

}

.space-between {
    display: flex;
    justify-content: space-between;
}



.bold-label {
    font-weight: bold;
}

.button-sign-in {
    width: 30%;
}

span.nav-dropdown-title {
    color: #34485e;
}

.filter-reset {
    background-color: #0694d5;
    color: white;
    text-align: center;
}

.filter-align-left  {
    background-color: #0694d5;
    color: white;
    text-align: left;
}

.filter-select-text {
    font-size: .75rem;
}

.search-box   {
    border: 0;
    padding: 0 !important;
    margin: 0 !important;
    background-color: #F3F3F3;
    width:100%;
}

.search-box:focus{
    border-color:#CCC;
    outline:0;

    box-shadow:none;
}

.filter-reset {
    padding-left: 0;
}
.filter-reset-text {
    font-size: .75rem;
}

.filter-data-text {
    font-size: 1.2rem;
}


.accordion-button {
    padding: 0 !important;
    background-color: #F3F3F3;
}

.accordion-button:not(.collapsed) {
    color: #34485e;
    background-color: #F3F3F3;
    box-shadow: none;
    border-bottom:  1px solid  #E4E4E4;
}

accordion-button.collapsed {
    color: #34485e;
    background-color: #F3F3F3;
}


.color-dropdown{
    color: #0694d5;
}

.btn-primary-outline {
    background-color: #E2E2E2;
    border-color: #E2E2E2;

}

.select-files-link {
    color: #7abe43;
}

.border-select {
    border: 1px solid red;
}

.border-blue {
    border: 1px solid blue;
}

.select-files-background {
    background-color: #E2E2E2;
    padding-left:2%;
}
.select-files {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border:1px solid blue;
}

.selector-div {
    display: flex;
    flex-direction: column;

    align-items: flex-start;
    width: 98%;
    margin-left: 2%;

}

span.share {
    display: block;
    color: white;
}

span.share-title{
  font-size: .875rem;

}
span.share-header {
   font-weight: bold;
}

.study-name{
    background-color: #273746;

}

.study-phase{
    background-color: #728596;

}

span.subheader {
   color: #34485e;
    font-size: .875rem;

}
a.dsa {
    font-size: .875rem;
    color:  #7abe43;
    font-weight: bold;
    cursor: pointer;

}

a.convert {
    font-size: .875rem;
    color:  #0d6efd;
    cursor: pointer;

}

.icon {
    width: 20%;

}

.completion-date{
    background-color: #ACBDCC;

}

.clin-trial {
    background-color: #E4E4E4;

}
.submission-status {

    background-color: #F3F3F3;
}

span.icon {
    display: block;
}


.selectorFileType-div {
    display: flex;
    flex-direction: column;
    border: 1px solid green;
    align-items: flex-start;
    background-color: #F3F3F3;
    width: 100%;
    padding-left: 5%;
    padding-bottom: 3%;


}

.form-control:disabled {

     background-color: white !important;
     border:0;
    padding-left: 0;
 }

.grid-entry{
    flex: 1;
    border: 0px;
    padding-left:0;
    padding-right:0;
    font-size: .875rem;

    font-size: .875rem;
    color: #34485e;
}

.grid-header{
    font-weight: bold;

}

.space-3 {
    flex: 3;



}

.space-2 {
    flex: 2;


}

.space-4 {
    flex: 4;


}

.space-5 {
    flex: 5;
}

.space-6 {
    flex: 6;
}

span.dashboard-admin {
    display: block;
    font-weight: bold;
}

.border-active {
    border-color:  #0694D5 !important;
}

.border-inactive {
    border-color: #B2B2B2 !important;
}

#dashboardCircle {
    height: 48px;
    width: 48px;
    display: table-cell;;
    text-align: center;
    vertical-align: middle;
    border-radius: 50%;
    background: #273747;
    color: white;
}

.border-green {
    border: 1px solid green;

}

.border-orange {
    border: 1px solid orange;

}

.border-search {
    border: 1px solid #34485e;
    border-radius: 0;
    background-color: white;

}

.clin-drug-select {
    background-color:#F1F1F1 ;
}

.login-width{
    width: 450px;
}

.login-form{
    width: 500px;
}

.no-rounded-border {
    border-radius: 0 !important;
}


.login-password {
   border-top: 1px solid #929292 !important;
   border-left: 1px solid #929292 !important;
   border-bottom: 1px solid #929292 !important;
   border-right: 0px;
}

.login-email {
    width:475px;
    border: 1px solid #929292 !important;
}


#dropdown-basic::after{
    display: none;
}


.form-check-input.is-valid~.form-check-label{
    color: black !important
}

.spinner-center {
    margin: 5% 50%;
}

