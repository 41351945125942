body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #697682;
  background-color: white;
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 100%;
  box-sizing: border-box;
  font-family: 'Source Sans Pro', sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
