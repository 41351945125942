.proctitle
{
    background-color: #FAFBFE;
    color: #112277;
    font-family: Arial, 'Albany AMT', Helvetica, Helv;
    font-size: x-small;
    font-style: normal;
    font-weight: bold;
}

.table
{
    border-bottom-width: 0px;
    border-collapse: collapse;
    border-color: #C1C1C1;
    border-left-width: 1px;
    border-right-width: 0px;
    border-spacing: 0px;
    border-style: solid;
    border-top-width: 1px;
}

.rowheader
{
    padding: 0 !important;
    margin: 0 !important;
    background-color: #EDF2F9 !important;
    border-bottom-width: 1px !important;
    border-color: #B0B7BB;
    border-left-width: 0px;
    border-right-width: 1px;
    border-style: solid;
    border-top-width: 0px;
    color: #112277 !important;
    font-family: Arial, 'Albany AMT', Helvetica, Helv;
    font-size: x-small;
    font-style: normal;
    font-weight: bold;
}

.data
{
    padding: 0 !important;
    margin: 0 !important;
    background-color: #FFFFFF !important;
    border-bottom-width: 1px !important;
    border-color: #C1C1C1;
    border-left-width: 0px;
    border-right-width: 1px;
    border-style: solid;
    border-top-width: 0px;
    font-family: Arial, 'Albany AMT', Helvetica, Helv;
    font-size: x-small;
    font-style: normal;
    font-weight: normal;
}

.header
{
    background-color: #EDF2F9 !important;
    border-bottom-width: 1px !important;
    border-color: #B0B7BB;
    border-left-width: 0px;
    border-right-width: 1px;
    border-style: solid;
    border-top-width: 0px;
    color: #112277 !important;
    font-family: Arial, 'Albany AMT', Helvetica, Helv;
    font-size: x-small;
    font-style: normal;
    font-weight: bold;
}

.l {text-align: left }
.c {text-align: center }
.r {text-align: right }
.d {text-align: right }
.j {text-align: justify }
.t {vertical-align: top }
.m {vertical-align: middle }
.b {vertical-align: bottom }
.stacked_cell{padding: 0 }

